<template>
  <div class="DashBoard">
    <div v-if="carregando == true" class="carregando">
      <img width="100" src="./../assets/images/chek.png" />
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div>
      <Topo @OpenMenuLateral="menuOpen = true"></Topo>
      <b-row
        :style="
          `background-color:${temaCor.topoApp};color:${temaCor.CorItensCabecalho}`
        "
      >
        <b-col class="container"><h4 class="my-3 ml-3">Favoritos</h4></b-col>
      </b-row>
      <div class="p-2">
        <b-row class="mt-2" v-if="favoritos.length > 0">
          <b-col>
            <div :style="heightGeral" style="overflow: auto;margin-bottom:38px">
              <div ref="ofertas">
                <b-card
                  v-for="item in favoritos"
                  :key="item.id"
                  no-body
                  class="overflow-hidden mb-3"
                  @click="OpenModalOferta(item)"
                >
                  <b-row no-gutters>
                    <b-col cols="3">
                      <img :src="item.Marca" class="rounded-0" width="100%" />
                    </b-col>
                    <b-col>
                      <div class="contInterno">
                        <h4>{{ item.Nome }}</h4>
                        <span>{{ item.CategoriaNome }}</span>
                        <div
                          v-if="item.Beneficio"
                          class="beneficio"
                          :style="
                            `background-color:${temaCor.topoApp};color:${temaCor.CorItensCabecalho}`
                          "
                        >
                          {{ item.Beneficio }}
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-else class=" mt-4">
          <b-col>
            <div class="alert alert-danger">
              Nenhum Estabelecimento Favorito Encontrado
            </div>
          </b-col>
        </b-row>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <b-modal
      v-model="modalDetalhe"
      hide-footer
      centered
      :title="itemDetalhe.Nome"
      ><div class="mb-5">
        <DetalheOferta
          :itemOferta="itemDetalhe"
          :itemClick="itemClick"
        ></DetalheOferta>
      </div>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
import MenuRodape from "./MenuRodape";
import Topo from "./Topo";
import MenuLateral from "./MenuLateral";
import DetalheOferta from "./DetalheOferta";
import { Carousel, Slide } from "vue-carousel";
import service from "../services";
import moment from "moment";
export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      flipped: false,
      dados: {},
      temaCor: {},
      carregando: false,
      modalDetalhe: false,
      itemDetalhe: {},
      itemClick: null,
      heightGeral: { height: "375px" },
      favoritos: [],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { Topo, MenuRodape, MenuLateral, Carousel, Slide, DetalheOferta },
  methods: {
    toggleCard() {
      this.flipped = !this.flipped;
    },
    OpenModalOferta(item) {
      this.itemClick = item;
      this.carregando = true;
      var action = "detalheEstabelecimento";
      service
        .post("Fornecedores", action, { codigoEstabelecimento: item.Codigo })
        .then((res) => {
          this.modalDetalhe = true;
          this.itemDetalhe = res.data;
          this.carregando = false;
        });

      console.log(item);
    },
    formataRegional(id) {
      if (id) return id.toString().padStart(4, "0");
      else return "0000";
    },
    formataId(id) {
      if (id) return id.toString().padStart(6, "0");
      else return "000000";
    },
    formataDesde(data) {
      if (data) return moment(data).format("MM/YYYY");
    },
    formataAno(data) {
      if (data) return moment(data).format("YYYY");
    },
    returnaStyle() {
      var obj = {};
      obj.width = `${this.windowWidth}px`;
      obj.height = `${this.windowHeight - 120}px`;
      if (this.windowWidth > this.windowHeight)
        obj.height = `${this.windowHeight + 300}px`;
      return obj;
    },
    verificaToken() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      var favoritos = localStorage.getItem("favoritos");
      if (favoritos) this.favoritos = JSON.parse(favoritos);

      this.temaCor.topoApp = dadosEmpresa.CorBackgroundCabecalho;
      this.temaCor.CorItensCabecalho = dadosEmpresa.CorItensCabecalho;
      this.temaCor.CorTextoCartaoVirtual = dadosEmpresa.CorTextoCartaoVirtual;
    },
    getSize() {
      console.log(this.heightGeral);
      this.heightGeral.height = `${screen.height - 250}px`;
      console.log("////////////////////", document.height, screen.height);
      console.log(this.heightGeral);
    },
  },
  mounted() {
    this.verificaToken();
    this.getSize();
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>
<style lang="scss" scoped>
.card {
  border-radius: 10px !important;
  img {
    padding: 5px;
  }
}
.contInterno {
  display: flex;
  flex-direction: column;
  h4 {
    margin: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  span {
    margin: 10px 0 0 10px;
  }
  .beneficio {
    font-weight: bolder;
    font-family: "robotobold";
    padding: 5px;
    background: #333;
    color: #fff;
    margin-top: -6px;
    align-self: flex-end;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomright: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}

.cartao {
  display: block;
  border-radius: 7px;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 20px;

  will-change: transform;
}

.informacoes {
  margin-top: -100px;
  font-size: 14px;
  text-align: left;
  margin-left: 30px;
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}

/* Form */
.flashcard-form {
  position: relative;
}

.cartaoImg {
  width: 100%;
}
</style>
